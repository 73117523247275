import $ from 'jquery'
import { getCookie, setCookie } from '/lib/cookie'

$(document).ready(function(){
  if( ! getCookie( 'mg-banner' ) ){
    const $notice = $('.js-news-banner')
    if ( $('body').hasClass('home') ) {
      $notice.slideDown()
    } else {
      $notice.show();
    }
    $('.js-news-banner .js-close-banner').click( function(){
      $notice.slideUp()
      setCookie('mg-banner', 'true', 1);
    })
  }
})
