import $ from 'jquery'

$(document).ready(initMainSlider)
if (window.acf) {
  window.acf.addAction('render_block_preview/type=slider', initMainSlider)
}

function initMainSlider() {
  if ($('.js-slider').length > 0) {
    $('.js-slider').not('.slick-initialized').slick({
      arrows: true,
      dots: true,
      prevArrow: '<button type="button" class="slick-prev"><span class="u-sr-only">Previous</span><svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 11L26 11" stroke="black"/><path d="M11 21L1.5 11L11 1" stroke="black"/></svg></button>',
      nextArrow: '<button type="button" class="slick-next"><span class="u-sr-only">Next</span><svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 11L8.9407e-07 11" stroke="black"/><path d="M15 21L24.5 11L15 1" stroke="black"/></svg></button>',
      adaptiveHeight: true
    });
  }
}
