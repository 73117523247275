import $ from 'jquery'

const $doc = $(document);
const button = $('.back-to-top');
let lastScroll = 0;

$(document).ready( function() {
  $(document).scroll(function () {
    const currentScroll = $(window).scrollTop();
    if (currentScroll <= 0) {
      button.removeClass('show');
      return;
    }
  
    if (currentScroll > lastScroll && button.hasClass('show')) {
      button.removeClass('show');
    } else if ( currentScroll < lastScroll && !button.hasClass('show') ) {
      button.addClass('show');
    }
    lastScroll = currentScroll;
  });
})


function scrollToTop(event) {
  event.preventDefault();
  $('html, body').animate({scrollTop : 0}, 1000);
}

$doc.on('click', '.js-scroll-to-top', scrollToTop )