const header = document.querySelector('.js-header')
const headerSearchToggle = document.querySelector('.js-header-search-toggle')
const headerSearchInput = document.querySelector('.js-search-input')

headerSearchToggle?.addEventListener('click', () => {
  header.classList.toggle('header__search-active')
  if (header.classList.contains('header__search-active') ) {
    headerSearchToggle.setAttribute('aria-expanded', 'true');
  } else {
    headerSearchToggle.setAttribute('aria-expanded', 'false');
  }
  // Focus on input when search is opened
  if (header.classList.contains('header__search-active')) {
    headerSearchInput.focus()
  }
})
